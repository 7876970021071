<template>
  <div class="page has-background-blue with-title">
    <h1
      v-if="book"
      class="page-title has-text-weight-bold has-text-centered is-size-5"
    >
      {{ book.title }}
    </h1>
    <div
      v-if="book"
      class="container p-4"
    >
      <div class="columns is-multiline is-mobile">
        <generic-mosaic-item
          v-for="item in book.medias"
          :key="item.id"
          :data="item"
          @click="openMedia(item)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import GenericMosaicItem from "@/components/lists/GenericMosaicItem";

export default {
  name: 'MediaBookDetail',
  components: {GenericMosaicItem},
  title: 'Libro de interactivo',
  data: function () {
    return {
      bookId: null,
      book: null
    }
  },
  async beforeMount() {
    this.bookId = this.$router.currentRoute.params.bookId
    if (this.bookId) {
      await this.loadContent()
    }
  },
  methods: {
    async loadContent() {
      try {
        await this.$store.dispatch('initLoading')

        this.book = await this.$api.media.getBook(this.bookId)

        this.setPageTitle(this.book.title)

        await this.$store.dispatch('finishLoading')
      } catch (error) {
        await this.$store.dispatch('finishLoading')

        await this.handleContentError(error)
      }
    },
    async openMedia(item) {
      const id = item.id
      await this.$router.push({name: 'media-detail', params: {id}, query: {book: this.bookId}})
    }
  }
}
</script>
